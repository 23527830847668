<template>
  <div class="container">
    <div>
      <div v-if="!isStopped">
        <h1 class="exam-title my-5 text-center mt-3 w-color-primary">{{testName}}</h1>
        <b-row>
          <b-col cols="9">
            <div class="table a-shadow">
              <div class="header p-3 px-4">
                <div class="d-flex align-items-center justify-content-between">
                  <p>Počet zúčastnených: {{totalStudents}}</p>
                </div>
              </div>
              <div class="questions">
                <div class="d-flex">
                  <b-col v-for="(que, index) in questionsCount" :key="index" class="question-column" >
                    <div class="top py-2 text-center">
                      <p class="m-0">{{index + 1}}</p>
                      <p class="m-0">otázka</p>
                    </div>
                    <div class="users">
                      <div 
                        class="users-count" 
                        :style="{ height: questionUserCount[index] ? questionUserCount[index] * 10 + '%' : 0 }"
                      >
                        <span v-if="questionUserCount[index]">{{questionUserCount[index]}}</span>
                        <span v-else>0</span>
                      </div>
                    </div>
                  </b-col>
                </div>
              </div>
            </div>
            <div class="answers a-shadow">
              <div class="header d-flex justify-content-between p-3 px-4">
                <p>Pomer odpovedí</p>
                <div class="d-flex align-items-center">
                  <p class="mr-3"><span class="green"></span> Správne: {{ratio.correct}}%</p>
                  <p class="mr-3"><span class="red"></span> Nesprávne: {{ratio.wrong}}%</p>
                </div>
              </div>
              <div class="bar p-4 d-flex">
                <div class="bar-status correct" :style="{width: ratio.correct + '%'}"></div>
                <div class="bar-status wrong" :style="{width: ratio.wrong + '%'}"></div>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="ranking h-100 a-shadow">
              <div class="header p-3 px-4">
                <p>Poradie účastníkov</p>
              </div>
              <div 
                v-for="(user, index) in students" :key="index"
                class="user-rank d-flex align-items-center justify-content-between px-3 py-2"
              >
                <div class="d-flex align-items-center ">
                  <p class="m-0 mr-3 rank d-flex align-items-center justify-content-center">{{index + 1}}</p>
                  <p class="m-0">{{user.name}}</p>
                </div>
                <p class="m-0 justify-self-end points">{{user.stats.score}}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else class="p-5 text-center">
        <h3 class="w-color-primary">Test s názvom {{testName}} bol zastavený.</h3>
      </div>
    </div>
  </div>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import collect from 'collect.js'

export default {
  name: 'Dashboard',

  data() {
    return {
      isStopped: false,
      questionsCount: '',
      totalStudents: '',
      students: [],
      testName: '',
      ratio: {
        correct: 0,
        wrong: 0,
        empty: 0,
        totalAnswers: 0
      },
      questionUserCount: []
    }
  },

  mounted() {
    if (this.dashboardId) {
      this.getData(this.dashboardId, this)
      this.intervalFetchData()
    }
  },

  computed: {
    dashboardId() {
      return this.$route.params.id
    }
  },
  
  methods: {
    getData(dHash, t) {
      wAxios.post(`/v1/teacher/exams/` + dHash)
        .then((response) => {
          t.isStopped = response.data.data.stopped_at
          t.questionsCount = response.data.data.template.exam_questions_count
          t.totalStudents = response.data.data.students.length
          t.students = response.data.data.students
          t.testName = response.data.data.name
        }).then(() => {
          let correctTotal = 0
          let wrongTotal = 0
          let total = 0
          let emptyTotal = 0
          const usersOnQuestion = []

          t.students.forEach((student) => {
            correctTotal += student.stats.answers.correct
            wrongTotal += student.stats.answers.wrong
            total += student.stats.answers.correct + student.stats.answers.wrong + student.stats.answers.empty
            emptyTotal += student.stats.answers.empty

            t.ratio.correct = correctTotal > 0 ? (correctTotal / total * 100).toFixed(2) : correctTotal
            t.ratio.wrong = wrongTotal > 0 ? (wrongTotal / total * 100).toFixed(2) : wrongTotal
            t.ratio.empty = emptyTotal > 0 ? (emptyTotal / total * 100).toFixed(2) : emptyTotal
            t.ratio.totalAnswers = correctTotal

            usersOnQuestion.push(student.stats.answers.total)
          })

          t.questionUserCount = collect(usersOnQuestion).countBy().all()
        })
    },

    intervalFetchData: function () {
      setInterval(() => {
        this.getData(this.dashboardId, this)
      }, 5000)
    }
  }
}
</script>

<style lang="sass" scoped>
.a-shadow
  -webkit-box-shadow: 0 0 15px 6px rgba(184,184,184,0.42)
  -moz-box-shadow: 0 0 15px 6px rgba(184,184,184,0.42)
  box-shadow: 0 0 15px 6px rgba(184,184,184,0.42)
  border-radius: 5px

.header
  border-bottom: 1px solid #eee
  p
    margin: 0

.question-column
  border-right: 1px solid #eee
  padding: 0
  &:last-child
    border-right: none
  .top
    border-bottom: 1px solid #eee
    p
      line-height: 1

.users
  height: 300px
  position: relative

.users-count
  width: 100%
  position: absolute
  bottom: 0
  background: var(--a-color-primary)
  span
    width: 25px
    height: 25px
    border-radius: 50%
    border: 1px solid #fff
    text-align: center
    color: #fff
    font-size: 13px
    display: flex
    align-items: center
    justify-content: center
    line-height: 1
    position: absolute
    left: 50%
    bottom: 10px
    margin-left: -12px
    background: #EA3EFB

.user-rank
  border-bottom: 1px solid #eee
  p
    font-size: 13px
    &.rank
      width: 26px
      height: 26px
      background: #EA3EFB
      border-radius: 50%

.answers
  .header
    border-bottom: 1px solid #eee
    p
      span
        width: 10px
        height: 10px
        border-radius: 50%
        display: inline-block
        &.green
          background: #00e600
        &.red
          background:  #ff471a
        &.grey
          background: #ccc

  .bar
    .bar-status
      width: 100%
      height: 30px
      &.correct
        background: #00e600
      &.wrong
        background:  #ff471a
      &.neutral
        background: #ccc

</style>